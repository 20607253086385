import React from 'react'
import {Link} from 'gatsby'

const BonsPlansJeunesListItem = ({postIndex, postItem}) => {
	const {id, nom_dispositif, support_visuel, sous_thematique} = postItem
	let nom_dispositif_ref = nom_dispositif.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/ /g,'-').replace(/[^\w-]+/g,'');
	nom_dispositif_ref = nom_dispositif_ref.trim() + '-' + id ;
	return (
		<Link className="" to={`/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/annuaire-bons-plans-jeunes/${nom_dispositif_ref}`} key={postIndex}>
			<div className="bons-plans-jeunes-list-item-content">
                <div className="bons-plans-jeunes-list-item-content-picture" >
					<img alt={nom_dispositif || ''} src={support_visuel} />
					{sous_thematique && <p className="bons-plans-jeunes-list-item-content-cateory">{sous_thematique}</p>}
				</div>
				<div className="bons-plans-jeunes-list-item-content-info">
					{nom_dispositif &&
						<h2 
							className="bons-plans-jeunes-list-item-content-title"
							dangerouslySetInnerHTML={{ __html: nom_dispositif }}
						></h2>
					}
				</div>
			</div>
		</Link>
	)
}

export default BonsPlansJeunesListItem
