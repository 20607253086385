import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'

import SocialShare from "../../SocialShare"

import BonsPlansJeunesBreadcrumb from '../BonsPlansJeunesBreadcrumb'
import ScrollTop from '../../ScrollTop'

import { getCookiebox } from '../../../state/reducers/cookiebox'


const BonPlansJeunesSingleItem = ({postItem, shareUrl, cookiebox}) => 
	<div>
		{postItem && 
			<div>
				<Helmet>
					<title>{postItem.nom_dispositif || ''}</title>
				</Helmet>
				<BonsPlansJeunesBreadcrumb SingleTitle={postItem.nom_dispositif || ''} />
				<Container className="bons-plans-jeunes-single-item">
					<Row>
						<Col tag="aside" lg="4" md="12" >
							<div className="anchors-sidebar h-100 pr-5">
								<div className="bg-light h-100 bons-plans-jeunes-single-item-left">
									{postItem.adresse_principale &&
										<p className="bons-plans-jeunes-single-item-field bons-plans-jeunes-single-item-location" >
											<a rel="noopener noreferrer" href={`http://maps.google.fr/maps?f=q&hl=fr&q=${postItem.adresse_principale}`} target="_blank" >
												{postItem.adresse_principale && <>{postItem.adresse_principale}<br /></>}
											</a>
										</p>
									}
									{ ( postItem.contact || postItem.site_internet ) &&
										<p className="bons-plans-jeunes-single-item-field bons-plans-jeunes-single-item-contact" >
											{postItem.contact && <>{postItem.contact}<br /></>}
											{postItem.site_internet && <><a rel="noopener noreferrer" href={postItem.site_internet} target="_blank" >Pour plus d'infos</a><br /></>}
										</p>
									}
									<SocialShare shareUrl={shareUrl}/>
								</div>
							</div>
						</Col>
						<Col tag="article" md="12" lg="8" className="py-md-5 page-content position-relative">
							<div className="bons-plans-jeunes-single-item-right" >
								<Link to="/services-infos-pratiques/jeunesse/bons-plans-pour-les-jeunes/annuaire-bons-plans-jeunes/" className="bons-plans-jeunes-single-link-back d-lg-none d-md-none d-sm-block d-xs-block " >Retourner vers les bons plans jeunes</Link>
								{postItem.nom_dispositif && <h1 className="bons-plans-jeunes-single-item-title" >{postItem.nom_dispositif}</h1>}
								{postItem.thematique && <p className="bons-plans-jeunes-single-item-content-category">{postItem.thematique}</p>}
								{postItem.sous_thematique && <p className="bons-plans-jeunes-single-item-content-subcategory">{postItem.sous_thematique}</p>}
								{postItem.support_visuel &&
									<p className="bons-plans-jeunes-single-item-picture" >
										<span className="bons-plans-jeunes-single-item-picture-flou" style={{backgroundImage: 'url(' +postItem.support_visuel + ')'}} ></span>
										<img alt={postItem.nom_dispositif || ''} src={postItem.support_visuel} />
									</p>
								}
								{postItem.pour_qui &&
									<>
										<p className="bons-plans-jeunes-single-item-label" >
											Pour qui ?
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.pour_qui}
										</p>
									</>
								}
								{postItem.quelles_aides &&
									<>
										<p className="bons-plans-jeunes-single-item-label" >
											Quelle aide ?
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.quelles_aides}
										</p>
									</>
								}
								{postItem.quelles_aides &&
									<>
										<p className="bons-plans-jeunes-single-item-label" >
											Comment en bénéficier ?
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.comment_en_beneficier}
										</p>
									</>
								}
								{postItem.structure_contact &&
									<>
										<p className="bons-plans-jeunes-single-item-label" >
											Qui contacter ?
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.structure_contact && <>{postItem.structure_contact}<br /></>}
											{postItem.adresse_principale && <>{postItem.adresse_principale}<br /></>}
											{postItem.contact && <>{postItem.contact}<br /></>}
											{postItem.courriel_1 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_1}`} target="_blank">{postItem.courriel_1}</a><br /></>}
											{postItem.site_internet && <><a rel="noopener noreferrer" href={postItem.site_internet} target="_blank">Site internet</a><br /></>}
											{postItem.facebook && <><a rel="noopener noreferrer" href={postItem.facebook} target="_blank">Page facebook</a><br /></>}
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.adresse_2 && <>{postItem.adresse_2}<br /></>}
											{postItem.contact_2 && <>{postItem.contact_2}<br /></>}
											{postItem.courriel_2 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_2}`} target="_blank">{postItem.courriel_2}</a><br /></>}
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.adresse_3 && <>{postItem.adresse_3}<br /></>}
											{postItem.contact_3 && <>{postItem.contact_3}<br /></>}
											{postItem.courriel_2 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_2}`} target="_blank">{postItem.courriel_2}</a><br /></>}
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.adresse_4 && <>{postItem.adresse_4}<br /></>}
											{postItem.contact_4 && <>{postItem.contact_4}<br /></>}
											{postItem.courriel_4 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_4}`} target="_blank">{postItem.courriel_4}</a><br /></>}
										</p>
										<p className="bons-plans-jeunes-single-item-text" >
											{postItem.adresse_5 && <>{postItem.adresse_5}<br /></>}
											{postItem.contact_5 && <>{postItem.contact_5}<br /></>}
											{postItem.courriel_5 && <><a rel="noopener noreferrer" href={`mailto:${postItem.courriel_5}`} target="_blank">{postItem.courriel_5}</a><br /></>}
										</p>
									</>
								}
							</div>
							<ScrollTop/>
						</Col>
					</Row>
				</Container>
			</div>
		}
	</div>

const mapStateToProps = state => ({
	cookiebox: getCookiebox(state)
})

export default connect(mapStateToProps)(BonPlansJeunesSingleItem)
