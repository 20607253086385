import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as loadingActions from '../../../state/actions/loading'

import BonPlansJeunesSingleItem from "./BonPlansJeunesSingleItem"

class BonPlansJeunesSingle extends Component {
	_isMounted = false
	
	constructor(props, context) {
		super(props, context)

		const {urlSingle} = this.props

		// Liste des filtres actifs
		this.state = {
			urlSingle: urlSingle ? urlSingle.trim() : '',
			post: []
		}
	}

	componentDidMount(){
		this._isMounted = true
		const {loadingOn} = this.props

		this.launchSingle()
		loadingOn('BonsPlansJeunesSingle')
	}

	componentWillUnmount() {
		const {loadingOff} = this.props

		loadingOff('BonsPlansJeunesSingle')
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		prevState.urlSingle !== this.state.urlSingle && this.launchSingle()
	}

	launchSingle() {
		const {loadingOff} = this.props
		const {urlSingle} = this.state
        let id = urlSingle.split('-')
        id = id[id.length-1]

		if( parseInt( id ) > 0 ) {
			fetch('https://opendata.roubaix.fr/api/records/1.0/search/?dataset=les-dispositifs-pour-les-jeunes-a-roubaix&q=id%3D' + id + '&facet=thematique&facet=sous_thematique&facet=tranche_d_age&facet=id',
            {
                    headers: {
                    },
                    method: "GET"
                }
            ).then(response => {
				if (response.status && response.status > 200 ) {
					console.warn(response)
				} else {
					return response.json()
				}
			}).then(({records}) => {
				this._isMounted && this.setState({ 
					post:records[0]
				})
				loadingOff('BonsPlansJeunesSingle')
			}).catch(function(error) {
				console.warn("error")
				console.warn(error)
			})
		}
	}

	render(){
		const {location} = this.props
		const {post} = this.state

		return (
			<div>
				<div className="offline-fallback-fix-no-idea-why"></div>
				<div className="bons-plans-jeunes-single">
					<BonPlansJeunesSingleItem 
						shareUrl={location.href}
						postItem={post.fields}
					/>
				</div>
			</div>
		)
	}
}

export default connect(null, loadingActions)(BonPlansJeunesSingle)
